import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
// import { ButtonContent } from "../global/boton/ButtonContent";
import ServicesHome from "../Services/ServicesHome_2";
// import { FaLeaf } from 'react-icons/fa'
// import { Link } from "react-router-dom";

function ContentServicesHome() {
  const { rpdata } = useContext(GlobalDataContext);
  // const goToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };
  return (
    <div className="bg-white">
      <div className="md:w-[98%] mx-auto py-32 text-center ">
        <h2 className="text-center py-4 text-[#1f1f1f]">{rpdata?.labels?.general?.titleServices}</h2>
        {/* <ButtonContent btnStyle="three" btnName="view all services" btnLink={'services'} /> */}
        <div className="flex flex-wrap items-center justify-center">
          {rpdata?.dbServices?.slice(0, 8).map((item, index) => {
            return (
              <ServicesHome
                key={index}
                bgImg={item.description[0].img}
                serviceName={item.name}
                serviceText={item.description[0].text.substring(0, 160) + "..."}
              />
            );
          })}
        </div>
        {/* <div className="w-4/5 mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 pt-10">
          {
            rpdata?.dbServices?.map((item, index) => {
              return (
                <Link
                  key={index}
                  to={item.name.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase()}
                  className="flex items-center gap-3 pb-8"
                  onClick={goToTop}
                >
                  <FaLeaf className="text-[25px] text-[#379931]" />
                  <p className="text-[20px] font-semibold">{item.name}</p>
                </Link>
              )
            })
          }
        </div> */}
      </div>
    </div>
  );
}

export default ContentServicesHome;